import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo, useCallback, useMemo, useState } from 'react'
import {
    CalendarService,
    type Journey,
    type Step
} from '../../../../graphql/generated/autogenerated'
import { getJourneyIcon, getJourneyPalette } from '../../../../utils/journeys'
import { MicrostepCard } from '../../entities/microsteps'
import { JourneyChip } from '../../entities/microsteps/MicrostepCardChips'
import useMicrostepPlanStepCheck from './hooks/useMicrostepPlanStepCheck'
import MicrostepActions from './MicrostepActions'
import { useMediaQuery } from '@mui/material'
import { useMicrostepTracking } from '../../../../tracking/microsteps/microsteps'
import { type CalendarType } from '../../../microsteps/features/ScheduleMicrostepActionButton/calendarSchedulingUtils'

export type MicrostepProps = {
    step: Step
    disabled?: boolean
    onDislike?: (step: Step) => void
    onSchedulePopupOpen?: () => void
    onScheduleOptionSelected?: (calendarService: CalendarType) => void
}

function Microstep(props: MicrostepProps) {
    const {
        step,
        onDislike,
        disabled = false,
        onSchedulePopupOpen,
        onScheduleOptionSelected
    } = props
    const { id: stepId, checked, stepContent } = step
    const { title, body, id: contentId, journeys } = stepContent

    const [selected, setSelected] = useState(checked)

    const theme = useTheme()
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    const journey = useMemo<Journey>(() => {
        return journeys?.[0] ?? null
    }, [journeys])
    const journeyPalette = getJourneyPalette(theme.palette, journey?.coreType)
    const journeyIcon = getJourneyIcon(journey?.coreType)

    const { checkStep, unCheckStep } = useMicrostepPlanStepCheck()
    const { trackMicrostepChecked, trackMicrostepUnchecked } =
        useMicrostepTracking()

    const checkMicrostep = useCallback(() => {
        if (selected) {
            setSelected(false)
            unCheckStep({
                microstepIds: contentId,
                stepId: stepId
            })
            trackMicrostepUnchecked({
                microStepId: contentId,
                microstepBody: body,
                microstepName: title
            })
        } else {
            setSelected(true)
            checkStep({
                microstepIds: contentId,
                stepId: stepId
            })
            trackMicrostepChecked({
                microStepId: contentId,
                microstepBody: body,
                microstepName: title
            })
        }
    }, [
        body,
        checkStep,
        contentId,
        selected,
        stepId,
        title,
        trackMicrostepChecked,
        trackMicrostepUnchecked,
        unCheckStep
    ])

    return (
        <>
            <MicrostepCard
                id={contentId}
                title={title}
                body={body}
                selected={selected}
                disabled={disabled}
                onToggleClick={checkMicrostep}
                journeyType={journey?.coreType}
                chips={
                    journey && (
                        <JourneyChip
                            journeyShortName={journey?.shortName}
                            journeyPalette={journeyPalette}
                            journeyIcon={journeyIcon}
                            disabled={disabled}
                            variant={isMobileView ? 'icon' : 'full'}
                        />
                    )
                }
                actions={
                    <MicrostepActions
                        key={contentId}
                        step={step}
                        onDislike={!selected ? onDislike : null}
                        disabled={disabled}
                        onScheduleOptionSelected={onScheduleOptionSelected}
                        onSchedulePopupOpen={onSchedulePopupOpen}
                    />
                }
            />
        </>
    )
}

export default memo(Microstep)
