import {
    useCheckInToMicrostepMutation,
    useUnCheckinToMicrostepsMutation
} from '../../graphql/generated/autogenerated'
import { useAutoWaterPlants } from '@thriveglobal/thrive-web-achievements'
import { useCallback } from 'react'
import { format } from '@thriveglobal/thrive-web-core'
import { MICROSTEP_CHECKIN_DATE_FORMAT } from '../../constants'
import { GET_ALL_MICROSTEP_CHECKIN_PROGRESS } from '../../graphql/queries/microsteps/getMicrostepCheckinProgress'

export type CheckMicrostepParam = {
    microstepIds: string | string[]
    date?: string
}

export type UseMicrostepCheckOptions = {
    onChecked?: () => void
    onUnchecked?: () => void
}

export type UseMicrostepCheckResult = {
    checkMicrosteps: (arg: CheckMicrostepParam) => Promise<void>
    unCheckMicrosteps: (arg: CheckMicrostepParam) => Promise<any>
    isLoading: boolean
    isChecking: boolean
    isUnchecking: boolean
}

export function useMicrostepCheck(
    options?: UseMicrostepCheckOptions
): UseMicrostepCheckResult {
    const { onChecked, onUnchecked } = options || {}

    const [checkMicrosteps, { loading: isChecking }] =
        useCheckInToMicrostepMutation({
            refetchQueries: [GET_ALL_MICROSTEP_CHECKIN_PROGRESS]
        })
    const [unCheckMicrosteps, { loading: isUnchecking }] =
        useUnCheckinToMicrostepsMutation({
            refetchQueries: [GET_ALL_MICROSTEP_CHECKIN_PROGRESS]
        })

    const autoWaterPlants = useAutoWaterPlants()

    const handleCheckMicroStep = useCallback(
        ({ microstepIds, date }: CheckMicrostepParam) => {
            if (microstepIds.length === 0) return

            const checkInDate = format(
                new Date(),
                MICROSTEP_CHECKIN_DATE_FORMAT
            )

            return checkMicrosteps({
                variables: {
                    microstepIds: microstepIds,
                    date: date || checkInDate
                }
            })
                .then(() => {
                    onChecked?.()
                })
                .then(autoWaterPlants)
        },
        [autoWaterPlants, checkMicrosteps, onChecked]
    )

    const handleUnCheckMicrostep = useCallback(
        ({ microstepIds, date }: CheckMicrostepParam) => {
            if (microstepIds.length === 0) return

            const uncheckDate = format(
                new Date(),
                MICROSTEP_CHECKIN_DATE_FORMAT
            )

            return unCheckMicrosteps({
                variables: {
                    microstepIds: microstepIds,
                    date: date || uncheckDate
                }
            }).then(() => {
                onUnchecked?.()
            })
        },
        [onUnchecked, unCheckMicrosteps]
    )

    return {
        checkMicrosteps: handleCheckMicroStep,
        unCheckMicrosteps: handleUnCheckMicrostep,
        isLoading: isChecking || isUnchecking,
        isChecking,
        isUnchecking
    }
}
