import { defineMessages } from 'react-intl'
import { createMicrostepActionButton } from './createMicrostepActionButton'

export const intlMessages = defineMessages({
    ariaLabel: {
        defaultMessage: 'Like microstep',
        description: 'Like microstep button aria label'
    },
    label: {
        defaultMessage: 'Like',
        description: 'Like microstep button label'
    }
})

export const LikeActionButton = createMicrostepActionButton({
    ariaLabel: intlMessages.ariaLabel,
    label: intlMessages.label,
    actionName: 'like',
    shortIconName: 'thumbs-up'
})
