import { IconName } from '@fortawesome/fontawesome-common-types'

const JourneyIcons = new Map<string, IconName>([
    ['recharge', 'bed-front'],
    ['food', 'carrot'],
    ['move', 'person-walking'],
    ['focus', 'bullseye-arrow'],
    ['money', 'credit-card'],
    ['connect', 'heart'],
    ['stress_management', 'head-side-brain']
])

export default function getJourneyIcon(journeyName: string): IconName {
    return JourneyIcons.get(journeyName?.toLowerCase() || 'recharge')
}
