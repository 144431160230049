import { defineMessages } from 'react-intl'
import { createMicrostepActionButton } from './createMicrostepActionButton'

export const intlMessages = defineMessages({
    ariaLabel: {
        defaultMessage: 'Show less microsteps like this',
        description: 'Show less microsteps like this button aria label'
    },
    label: {
        defaultMessage: 'This isn’t relevant',
        description: 'This isn’t relevant button label'
    }
})

export const LessLikeThisActionButton = createMicrostepActionButton({
    ariaLabel: intlMessages.ariaLabel,
    label: intlMessages.label,
    actionName: 'more-like-this',
    fullIconName: 'thumbs-down',
    shortIconName: 'thumbs-down'
})
