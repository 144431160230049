import { IconName } from '@fortawesome/fontawesome-common-types'
import { type SimplePaletteColorOptions, Box, Stack } from '@mui/material'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { forwardRef, memo, useMemo } from 'react'

const BURST_ELEMENT_SIZES = [8, 12, 16, 20, 24]

type BurstAnimationElementsProps = {
    id: string
    journeyIcon: IconName
    journeyPalette: SimplePaletteColorOptions
}

const MicrostepToggleBurstElements = forwardRef<
    HTMLDivElement,
    BurstAnimationElementsProps
>(({ id, journeyIcon, journeyPalette }, ref) => {
    const shuffleSizes = useMemo(
        () =>
            BURST_ELEMENT_SIZES.map((size) => ({
                size,
                sort: Math.random()
            })).sort((a, b) => a.sort - b.sort),
        []
    )
    return (
        <Box ref={ref} position="absolute" width="100%" height="100%">
            {shuffleSizes.map(({ size }, index) => {
                return (
                    <Stack
                        key={`${id}-burst-element-${index}`}
                        role="presentation"
                        width={size}
                        height={size}
                        bgcolor={journeyPalette.main}
                        borderRadius="50%"
                        position="absolute"
                        top="0"
                        bottom="0"
                        left="0"
                        right="0"
                        margin="auto auto"
                        sx={{
                            transform: 'scale(0) translate(0, 0)',
                            transformOrigin: 'center'
                        }}
                        p={0.5}
                    >
                        <LeafIcon
                            icon={journeyIcon}
                            fontWeight={600}
                            sx={{
                                width: '100%',
                                height: '100%',
                                color: journeyPalette.contrastText
                            }}
                        />
                    </Stack>
                )
            })}
        </Box>
    )
})

export default memo(MicrostepToggleBurstElements)
