import { Button, Menu, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import uniqueId from 'lodash/uniqueId'
import { memo, useCallback, useMemo, useState, type MouseEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { type GraphQlMicrostep } from '../../../../graphql/generated/autogenerated'
import MicrostepSchedule365MenuItem from './MicrostepSchedule365MenuItem'
import MicrostepScheduleGoogleMenuItem from './MicrostepScheduleGoogleMenuItem'
import { CalendarType } from './calendarSchedulingUtils'

type ScheduleMicrostepActionButtonProps = {
    microstep: GraphQlMicrostep
    onScheduleMenuOpened?: () => void
    onScheduleMenuOptionClicked?: (calendarService: CalendarType) => void
    disabled?: boolean
}

function DynamicButton({
    expandButtonId,
    handleOpenActions,
    isActionsOpen,
    menuId,
    disabled = false,
    children
}) {
    const { breakpoints } = useTheme()
    const isMobileView = useMediaQuery(breakpoints.down('sm'))
    if (isMobileView) {
        return (
            <IconButton
                size="small"
                color="secondary"
                variant="contained"
                id={expandButtonId}
                onClick={handleOpenActions}
                disabled={disabled}
                aria-haspopup={'true'}
                aria-controls={isActionsOpen ? menuId : undefined}
                aria-expanded={isActionsOpen ? 'true' : undefined}
            >
                <LeafIcon icon="bell" fontSize="small" />
            </IconButton>
        )
    }

    return (
        <Button
            color="secondary"
            variant="contained"
            id={expandButtonId}
            onClick={handleOpenActions}
            disabled={disabled}
            aria-haspopup={'true'}
            aria-controls={isActionsOpen ? menuId : undefined}
            aria-expanded={isActionsOpen ? 'true' : undefined}
            startIcon={<LeafIcon icon="bell" fontSize="small" />}
            endIcon={<LeafIcon icon="chevron-down" fontSize="small" />}
        >
            {children}
        </Button>
    )
}

function ScheduleMicrostepActionButton(
    props: ScheduleMicrostepActionButtonProps
) {
    const {
        microstep,
        onScheduleMenuOpened,
        onScheduleMenuOptionClicked,
        disabled = false
    } = props
    const { expandButtonId, menuId } = useMemo(() => {
        return {
            expandButtonId: 'microstep-schedule-action-' + uniqueId(),
            menuId: 'microstep-schedule-menu-' + uniqueId()
        }
    }, [])

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
    const isActionsOpen = Boolean(menuAnchorEl)
    const handleOpenActions = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            onScheduleMenuOpened?.()
            setMenuAnchorEl(event.currentTarget)
        },
        [onScheduleMenuOpened]
    )
    const handleCloseActions = useCallback(() => {
        setMenuAnchorEl(null)
    }, [])

    return (
        <Stack data-testid="microstep-schecule-actions-menu">
            <DynamicButton
                expandButtonId={expandButtonId}
                handleOpenActions={handleOpenActions}
                isActionsOpen={isActionsOpen}
                menuId={menuId}
                disabled={disabled}
            >
                <CoreTypography customVariant="buttonNormal">
                    <FormattedMessage
                        defaultMessage="Remind Me"
                        description="Button to schedule a microstep"
                    />
                </CoreTypography>
            </DynamicButton>
            <Menu
                id={menuId}
                open={isActionsOpen}
                onClose={handleCloseActions}
                anchorEl={menuAnchorEl}
                MenuListProps={{
                    'aria-labelledby': expandButtonId
                }}
            >
                <MicrostepScheduleGoogleMenuItem
                    microstep={microstep}
                    onOptionClick={onScheduleMenuOptionClicked}
                />
                <MicrostepSchedule365MenuItem
                    microstep={microstep}
                    onOptionClick={onScheduleMenuOptionClicked}
                />
            </Menu>
        </Stack>
    )
}

export default memo(ScheduleMicrostepActionButton)
