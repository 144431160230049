import { useCallback } from 'react'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { defaultCategorySelectedProps } from '../defaultTrackingProps'

export type TrackingMicrostepsTabName = 'browse' | 'favorites' | 'completed'

export function useMicrostepsTabTracking() {
    const trackMicrostepTabSelected = useCallback(
        ({
            selectedTabName
        }: {
            selectedTabName: TrackingMicrostepsTabName
        }) => {
            Avo.categorySelected({
                ...defaultCategorySelectedProps,
                featureType: 'library',
                activityType: 'microstep_hub_sorting',
                displayText: selectedTabName
            })
        },
        []
    )

    const trackCompletedMicrostepButtonClicked = useCallback(() => {
        Avo.categorySelected({
            ...defaultCategorySelectedProps,
            featureType: 'library',
            activityType: 'microstep_hub_sorting',
            displayText: 'view_completed_microsteps'
        })
    }, [])

    return {
        trackMicrostepTabSelected,
        trackCompletedMicrostepButtonClicked
    }
}
