import { Card, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo, type ReactElement } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import MicrostepToggleButton from '../../../../../components/elements/MicrostepToggleButton/MicrostepToggleButton'

export const intlMessages = defineMessages({
    checkMicrostep: {
        defaultMessage: 'Check into microstep',
        description: 'Check microstep button'
    },
    cardAriaLabel: {
        defaultMessage: 'microstep-card',
        description: 'Microstep card'
    }
})

// Type to enforce that on toggle click and body are required if isCondensedMicrostep is false
type CheckInHiddenProps =
    | { isCondensedMicrostep: true; onToggleClick?: never; body?: never }
    | { isCondensedMicrostep?: false; onToggleClick: () => void; body: string }

export type MicrostepCardProps = {
    id: string
    title: string
    selected: boolean
    disabled?: boolean
    journeyType: string
    actions: ReactElement | ReactElement[]
    chips?: ReactElement | ReactElement[]
} & CheckInHiddenProps

function MicrostepCard(props: MicrostepCardProps) {
    const {
        id,
        title,
        body,
        selected,
        disabled = false,
        journeyType,
        onToggleClick,
        actions,
        chips,
        isCondensedMicrostep = false
    } = props

    const { spacing, palette } = useTheme()
    const { formatMessage } = useIntl()

    return (
        <Card
            sx={{
                width: '100%',
                height: '100%',
                padding: spacing(2),
                mb: spacing(2),
                ...(disabled
                    ? {
                          color: palette.action.disabled,
                          pointerEvents: 'none'
                      }
                    : {}),
                overflow: 'visible'
            }}
            aria-disabled={disabled}
            aria-label={formatMessage(intlMessages.cardAriaLabel)}
            data-testid={'microstep-card'}
        >
            <Stack height={'100%'} width={'100%'} direction={'row'} gap={2}>
                {!isCondensedMicrostep && (
                    <Stack>
                        <MicrostepToggleButton
                            id={`microstep-toggle-button-${id}`}
                            selected={selected}
                            value={selected}
                            disabled={disabled}
                            color={'success'}
                            onClick={onToggleClick}
                            size="small"
                            journeyType={journeyType}
                            aria-label={formatMessage(
                                intlMessages.checkMicrostep
                            )}
                        />
                    </Stack>
                )}

                <Stack height={'100%'} width={'100%'} spacing={1}>
                    <Stack
                        sx={{
                            flex: '1 1 auto'
                        }}
                        data-testid={'microstep-card-content'}
                    >
                        <CoreTypography variant={'h5'} component={'span'}>
                            {title}
                        </CoreTypography>
                        {!isCondensedMicrostep && (
                            <CoreTypography variant={'body2'}>
                                {body}
                            </CoreTypography>
                        )}
                    </Stack>
                    <Stack
                        direction={'row'}
                        sx={{
                            justifyContent: chips
                                ? 'space-between'
                                : 'flex-start',
                            flexDirection: isCondensedMicrostep
                                ? 'row-reverse'
                                : undefined,
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        {actions && (
                            <Stack
                                data-testid={'microstep-card-actions'}
                                sx={
                                    isCondensedMicrostep && {
                                        marginLeft: 'auto'
                                    }
                                }
                            >
                                {actions}
                            </Stack>
                        )}

                        {chips && (
                            <Stack
                                direction={'row'}
                                justifyContent={'start'}
                                data-testid={'microstep-card-chips'}
                            >
                                {chips}
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(MicrostepCard)
