import { defineMessages } from 'react-intl'
import { createMicrostepActionButton } from './createMicrostepActionButton'

export const intlMessages = defineMessages({
    ariaLabel: {
        defaultMessage: 'Share microstep',
        description: 'Share microstep button aria label'
    },
    label: {
        defaultMessage: 'Share',
        description: 'Share microstep button label'
    }
})

export const ShareActionButton = createMicrostepActionButton({
    ariaLabel: intlMessages.ariaLabel,
    label: intlMessages.label,
    actionName: 'share',
    fullIconName: 'share',
    shortIconName: 'share'
})
