import { useCallback } from 'react'
import type { TimeFrameOptionValue } from '../../pages/microsteps/widgets/ActivityTimeFrameWidget'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { defaultPromptSelectedProps } from '../defaultTrackingProps'

export function useMicrostepActivityDashboardTracking() {
    const trackTimelineSelected = useCallback(
        ({
            selectedTimeframeOption
        }: {
            selectedTimeframeOption: TimeFrameOptionValue
        }) => {
            let displayText: string = null

            switch (selectedTimeframeOption) {
                case 30:
                    displayText = '30 day activity'
                    break
                case 14:
                    displayText = '14 day activity'
                    break
                case 7:
                    displayText = '7 day activity'
                    break
            }

            Avo.promptSelected({
                ...defaultPromptSelectedProps,
                featureType: 'library',
                activityType: 'microstep_hub_dates_selected',
                displayText: displayText
            })
        },
        []
    )

    return {
        trackTimelineSelected
    }
}
