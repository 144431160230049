import { MenuItem } from '@mui/material'
import { Suspense, lazy, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { type GraphQlMicrostep } from '../../../../graphql/generated/autogenerated'
import {
    CalendarType,
    generateMicrostepBodyAndSubject
} from './calendarSchedulingUtils'

const ScheduleContentButton = lazy(() =>
    import('@thriveglobal/thrive-web-ms-teams-app').then((module) => ({
        default: module.ScheduleContentButton
    }))
)

interface ScheduleOption {
    microstep: GraphQlMicrostep
    onOptionClick?: (optionType: CalendarType) => void
}

function MicrostepSchedule365MenuItem(props: ScheduleOption) {
    const intl = useIntl()
    const { microstep, onOptionClick } = props

    const { microstepBody, microstepSubject } = useMemo(
        () =>
            generateMicrostepBodyAndSubject({
                microstepDescription: microstep.body,
                microstepTitle: microstep.title,
                microstepId: microstep.id,
                intl
            }),
        [microstep, intl]
    )

    return (
        <Suspense fallback={null}>
            {ScheduleContentButton && (
                <MenuItem disableRipple={true}>
                    <ScheduleContentButton
                        subject={microstepSubject}
                        body={microstepBody}
                        onScheduled={() =>
                            onOptionClick(CalendarType.Microsoft)
                        }
                        useRecommendedIcon={true}
                        variant="outlined"
                        sx={{ width: '100%' }}
                    />
                </MenuItem>
            )}
        </Suspense>
    )
}

export default memo(MicrostepSchedule365MenuItem)
