function runButtonAnimation(button: HTMLElement): Animation {
    return button.animate(
        [
            { transform: 'scale(0)' },
            { transform: 'scale(0)' },
            { transform: 'scale(0)' },
            { transform: 'scale(0)' },
            { transform: 'scale(0)' },
            { transform: 'scale(1.2)' },
            { transform: 'scale(1)' }
        ],
        {
            duration: 630,
            delay: 100,
            easing: 'ease-in-out'
        }
    )
}

function runCheckmarkAnimation(checkMark: HTMLElement): Animation {
    return checkMark.animate(
        [
            { transform: 'scale(0)' },
            { transform: 'scale(1.2)' },
            { transform: 'scale(1)' }
        ],
        {
            duration: 490,
            delay: 200,
            easing: 'ease-in-out'
        }
    )
}

function runJourneyIconAnimation(journeyIcon: HTMLElement): Animation {
    return journeyIcon.animate(
        [
            { transform: 'scale(0)' },
            { transform: 'scale(0.5)' },
            { transform: 'scale(0.95)' },
            { transform: 'scale(1)' }
        ],
        { duration: 400 }
    )
}

function runBurstAnimations(burstGroup: HTMLElement): Animation[] {
    const burstAnimations: Animation[] = []

    burstGroup.querySelectorAll('div').forEach((el, index) => {
        const angle = index * 0.2 * 2 * Math.PI
        const distance = Math.random() * 1 + 50
        const x = Math.cos(angle) * distance
        const y = Math.sin(angle) * distance

        const animation = el.animate(
            [
                {
                    offset: 0,
                    transform: `translate(${x / 2}px, ${y / 2}px) scale(0)`
                },
                {
                    offset: 0.33,
                    transform: `translate(${x / 2}px, ${y / 2}px) scale(0.9)`
                },
                {
                    offset: 0.6,
                    transform: `translate(${x / 2}px, ${y / 2}px) scale(1)`
                },
                {
                    offset: 1,
                    transform: `translate(${x}px, ${y}px) scale(1)`
                }
            ],
            {
                duration: 560,
                delay: 100,
                easing: 'ease-in-out'
            }
        )

        burstAnimations.push(animation)
    })

    return burstAnimations
}

export function initAnimationManager(
    button: HTMLElement,
    checkMark: HTMLElement,
    journeyIcon: HTMLElement,
    burstGroup: HTMLElement
) {
    let growButtonAnimation: Animation
    let growCheckmarkAnimation: Animation
    let growJourneyIconAnimation: Animation
    let burstAnimations: Animation[] = []

    return {
        runToggleAnimation() {
            growButtonAnimation = runButtonAnimation(button)
            growCheckmarkAnimation = runCheckmarkAnimation(checkMark)
            growJourneyIconAnimation = runJourneyIconAnimation(journeyIcon)
            burstAnimations = runBurstAnimations(burstGroup)

            growCheckmarkAnimation.onfinish = () => {
                growCheckmarkAnimation.cancel()
            }
            growButtonAnimation.onfinish = () => {
                growButtonAnimation.cancel()
            }
            growJourneyIconAnimation.onfinish = () => {
                growJourneyIconAnimation.cancel()
            }
            burstAnimations.forEach((animation) => {
                animation.onfinish = () => {
                    animation.cancel()
                }
            })
        },
        stopToggleAnimation() {
            growButtonAnimation?.cancel()
            growCheckmarkAnimation?.cancel()
            growJourneyIconAnimation?.cancel()
            burstAnimations.forEach((animation) => {
                animation.onfinish = () => {
                    animation.cancel()
                }
            })
        }
    }
}

export type AnimationManager = ReturnType<typeof initAnimationManager>
