import { defineMessages } from 'react-intl'
import { createMicrostepActionButton } from './createMicrostepActionButton'

export const intlMessages = defineMessages({
    ariaLabel: {
        defaultMessage: 'Show more microsteps like this',
        description: 'Show more microsteps like this button aria label'
    },
    label: {
        defaultMessage: 'More like this',
        description: 'More like this button label'
    }
})

export const MoreLikeThisActionButton = createMicrostepActionButton({
    ariaLabel: intlMessages.ariaLabel,
    label: intlMessages.label,
    actionName: 'more-like-this',
    fullIconName: 'thumbs-up',
    shortIconName: 'thumbs-up'
})
