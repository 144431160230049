import React, { memo, useCallback, useMemo, useState } from 'react'
import { MicrostepCardActions } from '../../entities/microsteps'
import { useMicrostepTracking } from '../../../../tracking/microsteps'
import once from 'lodash/once'
import { type Step } from '../../../../graphql/generated/autogenerated'
import { type CalendarType } from '../../../microsteps/features/ScheduleMicrostepActionButton/calendarSchedulingUtils'

export type MicrostepActionsProps = {
    step: Step
    onDislike?: (step: Step) => void
    disabled?: boolean

    onSchedulePopupOpen?: () => void
    onScheduleOptionSelected?: (calendarService: CalendarType) => void
}

function MicrostepActions(props: MicrostepActionsProps) {
    const {
        step,
        onDislike,
        disabled = false,
        onSchedulePopupOpen,
        onScheduleOptionSelected
    } = props
    const { id: stepId, stepContent } = step ?? {}
    const { title, id: contentId, body } = stepContent

    const [liked, setLiked] = useState(false)
    const [disliked, setDisliked] = useState(false)

    const { trackMicrostepLiked, trackMicrostepDisliked } =
        useMicrostepTracking()
    const trackMicrostepLikedOnce = useMemo(
        () => once(trackMicrostepLiked),
        [trackMicrostepLiked]
    )
    const trackMicrostepDislikedOnce = useMemo(
        () => once(trackMicrostepDisliked),
        [trackMicrostepDisliked]
    )

    const handleLike = useCallback(() => {
        trackMicrostepLikedOnce({
            microstepId: contentId,
            microstepTitle: title
        })
        setLiked(true)
    }, [contentId, title, trackMicrostepLikedOnce])

    const onDislikeOnce = useMemo(() => {
        const fn = onDislike ?? (() => {})
        return once(fn)
    }, [onDislike])
    const handleDislike = useCallback(() => {
        trackMicrostepDislikedOnce({
            microstepId: contentId,
            microstepTitle: title
        })
        onDislikeOnce(step)
        setDisliked(true)
    }, [contentId, onDislikeOnce, step, title, trackMicrostepDislikedOnce])

    return (
        <MicrostepCardActions
            microstep={step.stepContent}
            disabled={disabled}
            onDislike={onDislike ? handleDislike : null}
            disliked={disliked}
            onLike={handleLike}
            liked={liked}
            onScheduleMenuOpen={onSchedulePopupOpen}
            onScheduleOptionSelected={onScheduleOptionSelected}
        />
    )
}

export default memo(MicrostepActions)
