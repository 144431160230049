import { defineMessages } from 'react-intl'
import { createMicrostepActionButton } from './createMicrostepActionButton'

export const intlMessages = defineMessages({
    ariaLabel: {
        defaultMessage: 'Add to favorite microsteps',
        description: 'Bookmark microstep button aria label'
    },
    activeAriaLabel: {
        defaultMessage: 'Remove from favorite microsteps',
        description: 'Unbookmark microstep button aria label'
    },
    label: {
        defaultMessage: 'Add to Favorites',
        description: 'Bookmark microstep button label'
    },
    activeLabel: {
        defaultMessage: 'Remove favorite',
        description: 'Unbookmark microstep button label'
    }
})

export const FavoriteActionButton = createMicrostepActionButton({
    ariaLabel: intlMessages.ariaLabel,
    label: intlMessages.label,
    actionName: 'favorite',
    fullIconName: 'star',
    shortIconName: 'star',
    activeAriaLabel: intlMessages.activeAriaLabel,
    activeLabel: intlMessages.activeLabel,
    activeShortIconName: 'star',
    activeFullIconName: 'star'
})
