import React, { memo } from 'react'
import { Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { ShareActionButton } from './ShareActionButton'
import { LikeActionButton } from './LikeActionButton'
import { DislikeActionButton } from './DislikeActionButton'
import { BookmarkActionButton } from './BookmarkActionButton'
import ScheduleMicrostepActionButton from '../../../../../pages/microsteps/features/ScheduleMicrostepActionButton/ScheduleMicrostepActionButton'
import { type GraphQlMicrostep } from '../../../../../graphql/generated/autogenerated'
import { type CalendarType } from '../../../../microsteps/features/ScheduleMicrostepActionButton/calendarSchedulingUtils'

export type MicrostepCardActionsProps = {
    onShare?: () => void

    onLike?: () => void
    liked?: boolean

    onDislike?: () => void
    disliked?: boolean

    onBookmark?: () => void
    bookmarked?: boolean

    disabled?: boolean
    microstep?: GraphQlMicrostep

    onScheduleMenuOpen?: () => void
    onScheduleOptionSelected?: (calendarType: CalendarType) => void
}

function MicrostepCardActions(props: MicrostepCardActionsProps) {
    const {
        disabled = false,
        onShare,
        onBookmark,
        bookmarked,
        onLike,
        liked,
        onDislike,
        disliked,
        microstep,
        onScheduleMenuOpen,
        onScheduleOptionSelected
    } = props

    const { breakpoints, spacing } = useTheme()
    const isMobileView = useMediaQuery(breakpoints.down('sm'))

    return (
        <Stack
            width={'100%'}
            justifyContent={'space-between'}
            direction={'row'}
            data-testid={'microstep-card-actions'}
        >
            <Stack
                width={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
            >
                <Stack direction={'row'} spacing={1} sx={{ flexGrow: 1 }}>
                    <Stack
                        direction={'row'}
                        spacing={1}
                        width={'100%'}
                        alignItems={'center'}
                    >
                        {microstep && (
                            <Stack sx={{ pr: spacing(2) }}>
                                <ScheduleMicrostepActionButton
                                    microstep={microstep}
                                    onScheduleMenuOpened={onScheduleMenuOpen}
                                    onScheduleMenuOptionClicked={
                                        onScheduleOptionSelected
                                    }
                                    disabled={disabled}
                                />
                            </Stack>
                        )}
                        {onBookmark && (
                            <BookmarkActionButton
                                mode={'short'}
                                disabled={disabled}
                                onClick={onBookmark}
                                active={bookmarked}
                            />
                        )}
                        {onShare && (
                            <ShareActionButton
                                mode={isMobileView ? 'short' : 'full'}
                                disabled={disabled}
                                onClick={onShare}
                            />
                        )}
                        {onLike && (
                            <LikeActionButton
                                mode={'short'}
                                disabled={disabled}
                                onClick={onLike}
                                active={liked}
                            />
                        )}
                        {onDislike && (
                            <DislikeActionButton
                                mode={'short'}
                                disabled={disabled}
                                onClick={onDislike}
                                active={disliked}
                            />
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default memo(MicrostepCardActions)
