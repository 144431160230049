import { useCallback } from 'react'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import {
    defaultFilterSelectedProps,
    defaultListSortedProps
} from '../defaultTrackingProps'

export function useMicrostepsListTracking() {
    const trackTopicFilterSelected = useCallback(
        ({ selectedTopic }: { selectedTopic: string | null }) => {
            const displayText = !selectedTopic
                ? 'all_topics'
                : selectedTopic?.toLowerCase()

            Avo.filterSelected({
                ...defaultFilterSelectedProps,
                featureType: 'library',
                activityType: 'microstep_topic_filtering',
                displayText: displayText
            })
        },
        []
    )

    const trackSortFilterSelected = useCallback(
        ({
            selectedSortLabelKey,
            source
        }: {
            selectedSortLabelKey: string | null
            source: 'browse' | 'favorites'
        }) => {
            const activityType =
                source === 'browse'
                    ? 'microstep_browse_sorting'
                    : 'microstep_favorites_sorting'
            const displayText = selectedSortLabelKey?.toLowerCase()

            Avo.listSorted({
                ...defaultListSortedProps,
                featureType: 'library',
                activityType: activityType,
                displayText: displayText
            })
        },
        []
    )

    return {
        trackTopicFilterSelected,
        trackSortFilterSelected
    }
}
