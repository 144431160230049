import { defineMessages } from 'react-intl'
import { createMicrostepActionButton } from './createMicrostepActionButton'

export const intlMessages = defineMessages({
    ariaLabel: {
        defaultMessage: 'Expand microstep actions',
        description: 'Expand microstep actions button aria label'
    },
    label: {
        defaultMessage: 'Expand',
        description: 'Bookmark microstep button label'
    }
})

export const ExpandActionButton = createMicrostepActionButton({
    ariaLabel: intlMessages.ariaLabel,
    label: intlMessages.label,
    actionName: 'expand',
    fullIconName: 'ellipsis',
    shortIconName: 'ellipsis'
})
