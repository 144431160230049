import { Button, MenuItem, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { memo, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { type GraphQlMicrostep } from '../../../../graphql/generated/autogenerated'
import {
    CalendarType,
    generateMicrostepGoogleCalendarUrl
} from './calendarSchedulingUtils'

const GOOGLE_ICON_URL =
    'https://assets.thriveglobal.com/reset/icons/calendars/google.png'

interface ScheduleOption {
    microstep: GraphQlMicrostep
    onOptionClick?: (optionType: CalendarType) => void
}

function MicrostepScheduleGoogleMenuItem(props: ScheduleOption) {
    const { microstep, onOptionClick } = props

    const intl = useIntl()

    const handleClick = useCallback(() => {
        onOptionClick?.(CalendarType.Google)

        const calendarUrl = generateMicrostepGoogleCalendarUrl({
            microstepDescription: microstep.body,
            microstepTitle: microstep.title,
            microstepId: microstep.id,
            intl
        })
        window.open(calendarUrl, '_blank', 'noopener, noreferrer')
    }, [onOptionClick, microstep.body, microstep.title, microstep.id, intl])

    return (
        <MenuItem disableRipple={true}>
            <Stack direction={'row'} spacing={1}>
                <Button
                    variant="outlined"
                    sx={{ width: '100%' }}
                    onClick={handleClick}
                    startIcon={
                        <img
                            style={{
                                maxHeight: '3rem',
                                objectFit: 'contain'
                            }}
                            src={GOOGLE_ICON_URL}
                            aria-hidden="true"
                        />
                    }
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Google"
                            description="Button to schedule a microstep in Google Calendar"
                        />
                    </CoreTypography>
                </Button>
            </Stack>
        </MenuItem>
    )
}

export default memo(MicrostepScheduleGoogleMenuItem)
