import { type IntlShape, defineMessages } from 'react-intl'
import { generateMicrostepLink } from '../../../../utils/microsteps/generateMicrostepLink'
import { format } from '@thriveglobal/thrive-web-core'

export enum CalendarType {
    Google = 'google',
    Microsoft = 'microsoft' // depending on the platform the calendar type will be different (e.g. Microsoft Teams uses a calendar app, web uses Outlook)
}

const messages = defineMessages({
    microstepBody: {
        defaultMessage: `<div><strong>{microstepTitle}</strong></div><br /><div>{microstepDescription}</div><br /><div>Check-in to this Microstep in <link>Thrive</link></div>`,
        description: 'Template for creating a calendar event for a microstep'
    },
    microstepSubject: {
        defaultMessage: `Microstep reminder: {name}`,
        description: 'Email subject for microstep reminder'
    }
})

interface IBuildMicrostepBody {
    name: string
    body: string
    link: string
    intl: IntlShape
}
export function buildMicrostepBody({
    name,
    body,
    link,
    intl
}: IBuildMicrostepBody) {
    return intl.formatMessage(messages.microstepBody, {
        microstepDescription: body,
        microstepTitle: name,
        link: (...chunks) => `<a href="${link}">${chunks.join('')}</a>`,
        div: (...chunks) => `<div>${chunks.join('')}</div>`,
        strong: (...chunks) => `<strong>${chunks.join('')}</strong>`,
        br: (...chunks) => `<br />${chunks.join('')}`
    })
}

interface IBuildMicrostepSubject {
    name: string
    intl: IntlShape
}
export function buildMicrostepSubject({ name, intl }: IBuildMicrostepSubject) {
    return intl.formatMessage(messages.microstepSubject, { name })
}

interface IGenerateMicrostepCalendarUrl {
    microstepDescription: string
    microstepTitle: string
    microstepId: string
    intl: IntlShape
}

export function generateMicrostepBodyAndSubject({
    microstepDescription,
    microstepTitle,
    microstepId,
    intl
}: IGenerateMicrostepCalendarUrl): {
    microstepBody: string
    microstepSubject: string
} {
    const microstepBody = buildMicrostepBody({
        name: microstepTitle,
        body: microstepDescription,
        link: generateMicrostepLink(microstepId, true),
        intl
    })
    const microstepSubject = buildMicrostepSubject({
        name: microstepTitle,
        intl
    })

    return { microstepBody, microstepSubject }
}

export function generateMicrostepGoogleCalendarUrl({
    microstepDescription,
    microstepTitle,
    microstepId,
    intl
}: IGenerateMicrostepCalendarUrl): string {
    const { microstepBody, microstepSubject } = generateMicrostepBodyAndSubject(
        {
            microstepDescription,
            microstepTitle,
            microstepId,
            intl
        }
    )

    return buildGoogleCalendarUrl({
        microstepBody,
        microstepSubject
    })
}

interface IBuildGoogleCalendarUrl {
    microstepBody: string
    microstepSubject: string
}

export function buildGoogleCalendarUrl({
    microstepBody,
    microstepSubject
}: IBuildGoogleCalendarUrl): string {
    const GOOGLE_URL = 'https://calendar.google.com/calendar/u/0/r/eventedit'
    const GOOGLE_FREQUENCY_RULE = 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR'
    const googleCalendarEventDateFormat = 'YYYYMMDDTHHmmSS'
    const now = Date.now()
    const currentDateTime = format(new Date(now), googleCalendarEventDateFormat)

    const datesParamValue = `${currentDateTime}/${currentDateTime}`
    const calendarUrl = new URL(GOOGLE_URL)
    calendarUrl.searchParams.append('text', microstepSubject)
    calendarUrl.searchParams.append('details', microstepBody)
    calendarUrl.searchParams.append('recur', GOOGLE_FREQUENCY_RULE)
    calendarUrl.searchParams.append('dates', datesParamValue)
    return calendarUrl.toString()
}
