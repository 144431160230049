import { useCallback } from 'react'
import {
    useRemoveStepAsDoneMutation,
    useSetStepAsDoneMutation
} from '../../../../../graphql/generated/autogenerated'
import { useMicrostepCheck } from '../../../../../data/microsteps/useMicrostepCheck'

export type MicrostepPlanStepCheckProps = {
    microstepIds: string | string[]
    stepId: string
}

export type UseMicrostepPlanStepCheckResult = {
    checkStep: (data: MicrostepPlanStepCheckProps) => Promise<void>
    unCheckStep: (data: MicrostepPlanStepCheckProps) => Promise<void>
    isLoading: boolean
}

export function useMicrostepPlanStepCheck(): UseMicrostepPlanStepCheckResult {
    const {
        checkMicrosteps,
        unCheckMicrosteps,
        isLoading: isMicrostepCheckLoading
    } = useMicrostepCheck()

    const [checkStep, { loading: isCheckingStep }] = useSetStepAsDoneMutation()
    const [unCheckStep, { loading: isUncheckingStep }] =
        useRemoveStepAsDoneMutation()

    const handleCheckStep = useCallback(
        async (variables: MicrostepPlanStepCheckProps) => {
            const { microstepIds } = variables

            await Promise.allSettled([
                checkMicrosteps({ microstepIds }),
                checkStep({
                    variables: { microstepId: microstepIds }
                })
            ])
        },
        [checkMicrosteps, checkStep]
    )

    const handleUnCheckStep = useCallback(
        async (variables: MicrostepPlanStepCheckProps) => {
            const { stepId, microstepIds } = variables

            await Promise.allSettled([
                unCheckStep({
                    variables: { stepId }
                }),
                unCheckMicrosteps({ microstepIds })
            ])
        },
        [unCheckMicrosteps, unCheckStep]
    )

    return {
        checkStep: handleCheckStep,
        unCheckStep: handleUnCheckStep,
        isLoading: isMicrostepCheckLoading || isCheckingStep || isUncheckingStep
    }
}

export default useMicrostepPlanStepCheck
