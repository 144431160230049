import { useCallback } from 'react'
import { format } from '@thriveglobal/thrive-web-core'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import {
    defaultMicrostepAddedProps,
    defaultMicrostepCheckinCompletedProps,
    defaultMicrostepCheckingRemovedProps,
    defaultMicrostepServedProps,
    defaultPromptSelectedProps,
    defaultReactionSubmittedProps
} from '../defaultTrackingProps'
import { MICROSTEP_CHECKIN_DATE_FORMAT } from '../../constants'
import { type FeatureTypeValueType } from '@thriveglobal/thrive-web-tracking/dist/Avo'
import { type CalendarType } from '../../pages/microsteps/features/ScheduleMicrostepActionButton/calendarSchedulingUtils'

export const useMicrostepTracking = () => {
    const trackMicrostepLiked = useCallback(
        ({
            microstepId,
            microstepTitle
        }: {
            microstepId: string
            microstepTitle: string
        }) => {
            Avo.reactionSubmitted({
                ...defaultReactionSubmittedProps,
                featureType: 'journeys',
                activityType: 'microstep_like_submitted',
                feedbackAnswer: 'positive',
                contentType: 'microstep',
                contentId: microstepId,
                contentTitle: microstepTitle
            })
        },
        []
    )

    const trackMicrostepDisliked = useCallback(
        ({
            microstepId,
            microstepTitle
        }: {
            microstepId: string
            microstepTitle: string
        }) => {
            Avo.reactionSubmitted({
                ...defaultReactionSubmittedProps,
                featureType: 'journeys',
                activityType: 'microstep_dislike_submitted',
                feedbackAnswer: 'negative',
                contentType: 'microstep',
                contentId: microstepId,
                contentTitle: microstepTitle
            })
        },
        []
    )

    const trackMicrostepSchedulePopupOpened = useCallback(
        ({
            microstepName,
            microstepBody,
            microstepId,
            source = 'journeys'
        }: {
            microstepId: string
            microstepName: string
            microstepBody: string
            source?: 'microstep_hub' | 'journeys'
        }) => {
            const featureType: FeatureTypeValueType =
                source === 'journeys' ? 'journeys' : 'library'

            Avo.promptSelected({
                ...defaultPromptSelectedProps,
                featureType: featureType,
                activityType: 'microstep_reminder_selected',
                microstepId: microstepId,
                microstepName: microstepName,
                microstepBody: microstepBody
            })
        },
        []
    )

    const trackMicrostepSchedulePopupOptionSelected = useCallback(
        ({
            microstepId,
            microstepName,
            microstepBody,
            calendarService,
            source = 'journeys'
        }: {
            microstepId: string
            microstepName: string
            microstepBody: string
            calendarService: CalendarType
            source?: 'microstep_hub' | 'journeys'
        }) => {
            const featureType: FeatureTypeValueType =
                source === 'journeys' ? 'journeys' : 'library'
            const displayText: string = calendarService

            Avo.promptSelected({
                ...defaultPromptSelectedProps,
                featureType: featureType,
                activityType: 'microstep_reminder_set',
                microstepId: microstepId,
                microstepName: microstepName,
                microstepBody: microstepBody,
                displayText: displayText
            })
        },
        []
    )

    const trackMicrostepViewed = useCallback(
        ({
            microstepId,
            microstepName,
            microstepBody
        }: {
            microstepId: string
            microstepName: string
            microstepBody: string
        }) => {
            Avo.microstepServed({
                ...defaultMicrostepServedProps,
                featureType: 'journeys',
                activityType: 'microstep_served',
                microstepId: microstepId,
                microstepName: microstepName,
                microstepBody: microstepBody
            })
        },
        []
    )

    const trackMicrostepChecked = useCallback(
        ({
            microStepId,
            microstepName,
            microstepBody,
            source = 'journeys'
        }: {
            microStepId: string
            microstepName?: string
            microstepBody?: string
            source?: 'microstep_hub' | 'journeys'
        }) => {
            const activityType =
                source === 'journeys'
                    ? 'journey_microstep'
                    : 'microstep_management'

            const featureType: FeatureTypeValueType =
                source === 'journeys' ? 'journeys' : 'library'

            Avo.microstepCheckinCompleted({
                ...defaultMicrostepCheckinCompletedProps,
                activityType: activityType,
                featureType: featureType,
                microstepBody: microstepBody,
                microstepId: microStepId,
                microstepName: microstepName,
                isAutomaticCheckin: false,
                checkInDate: format(new Date(), MICROSTEP_CHECKIN_DATE_FORMAT)
            })
        },
        []
    )

    const trackMicrostepUnchecked = useCallback(
        ({
            microstepName,
            microstepBody,
            microStepId
        }: {
            microStepId: string
            microstepName?: string
            microstepBody?: string
        }) => {
            Avo.microstepCheckinRemoved({
                ...defaultMicrostepCheckingRemovedProps,
                activityType: 'journey_microstep',
                featureType: 'journeys',
                microstepId: microStepId,
                microstepName: microstepName,
                microstepBody: microstepBody,
                isAutomaticCheckin: false,
                checkInDate: format(new Date(), MICROSTEP_CHECKIN_DATE_FORMAT)
            })
        },
        []
    )

    const trackMicrostepExpanded = useCallback(
        ({
            microstepName,
            microstepBody,
            microstepId
        }: {
            microstepId: string
            microstepName: string
            microstepBody: string
        }) => {
            Avo.promptSelected({
                ...defaultPromptSelectedProps,
                featureType: 'library',
                activityType: 'microstep_expand_selected',
                microstepId,
                microstepName,
                microstepBody
            })
        },
        []
    )

    const trackMicrostepSaved = useCallback(
        ({ microstepId }: { microstepId: string }) => {
            Avo.microstepAdded({
                ...defaultMicrostepAddedProps,
                featureType: 'library',
                activityType: 'microstep_management',
                microstepId
            })
        },
        []
    )

    return {
        trackMicrostepChecked,
        trackMicrostepUnchecked,
        trackMicrostepLiked,
        trackMicrostepDisliked,
        trackMicrostepSaved,
        trackMicrostepSchedulePopupOpened,
        trackMicrostepSchedulePopupOptionSelected,
        trackMicrostepViewed,
        trackMicrostepExpanded
    }
}
