import { Box, type ButtonProps } from '@mui/material'
import { type MessageDescriptor, useIntl } from 'react-intl'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    LoadingButton
} from '@thriveglobal/thrive-web-leafkit'
import React, { type ComponentProps, forwardRef, memo } from 'react'
import { type IconName } from '@fortawesome/fontawesome-common-types'

export type MicrostepActionButtonConfig = {
    actionName: string
    shortIconName: IconName
    activeShortIconName?: IconName
    fullIconName?: IconName
    activeFullIconName?: IconName
    ariaLabel: MessageDescriptor
    activeAriaLabel?: MessageDescriptor
    label?: MessageDescriptor
    activeLabel?: MessageDescriptor
}

export type MicrostepActionButtonProps = {
    mode: 'short' | 'full'
    active?: boolean
    loading?: boolean
    iconProps?: Partial<ComponentProps<typeof LeafIcon>>
} & ButtonProps

export function createMicrostepActionButton(
    actionButtonConfig: MicrostepActionButtonConfig
) {
    const {
        actionName,
        label,
        ariaLabel,
        shortIconName,
        fullIconName = shortIconName,
        activeShortIconName = shortIconName,
        activeFullIconName = fullIconName,
        activeAriaLabel = ariaLabel,
        activeLabel = label
    } = actionButtonConfig

    const containerDataTestId = `${actionName}-action`
    const buttonDataTestId = `${actionName}-action-button`

    return memo(
        forwardRef<HTMLButtonElement, MicrostepActionButtonProps>(
            function MicrostepActionButton(
                props: MicrostepActionButtonProps,
                ref
            ) {
                const {
                    mode,
                    loading = false,
                    iconProps,
                    disabled,
                    active,
                    ...rest
                } = props

                const { formatMessage } = useIntl()

                return (
                    <Box data-testid={containerDataTestId}>
                        {mode === 'short' && (
                            <IconButton
                                ref={ref}
                                size="small"
                                variant={active ? 'contained' : 'outlined'}
                                disabled={disabled}
                                aria-label={formatMessage(
                                    active ? activeAriaLabel : ariaLabel
                                )}
                                data-testid={buttonDataTestId}
                                {...rest}
                            >
                                <LeafIcon
                                    icon={`${
                                        active
                                            ? activeShortIconName
                                            : shortIconName
                                    }`}
                                    fontSize="small"
                                    {...iconProps}
                                />
                            </IconButton>
                        )}
                        {mode === 'full' && (
                            <LoadingButton
                                ref={ref}
                                size={'medium'}
                                variant={active ? 'contained' : 'outlined'}
                                loading={loading}
                                disabled={disabled}
                                fixWidth={true}
                                aria-label={formatMessage(
                                    active ? activeAriaLabel : ariaLabel
                                )}
                                startIcon={
                                    <LeafIcon
                                        icon={`${
                                            active
                                                ? activeFullIconName
                                                : fullIconName
                                        }`}
                                        fontSize="small"
                                        {...iconProps}
                                    />
                                }
                                data-testid={buttonDataTestId}
                                {...rest}
                            >
                                <CoreTypography customVariant={'buttonSmall'}>
                                    {formatMessage(
                                        active ? activeLabel : label
                                    )}
                                </CoreTypography>
                            </LoadingButton>
                        )}
                    </Box>
                )
            }
        )
    )
}
