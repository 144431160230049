import { defineMessages } from 'react-intl'
import { createMicrostepActionButton } from './createMicrostepActionButton'

export const intlMessages = defineMessages({
    ariaLabel: {
        defaultMessage: 'Bookmark microstep',
        description: 'Bookmark microstep button aria label'
    },
    label: {
        defaultMessage: 'Bookmark',
        description: 'Bookmark microstep button label'
    }
})

export const BookmarkActionButton = createMicrostepActionButton({
    ariaLabel: intlMessages.ariaLabel,
    label: intlMessages.label,
    actionName: 'bookmark',
    fullIconName: 'bookmark',
    shortIconName: 'bookmark'
})
