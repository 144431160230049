import { defineMessages } from 'react-intl'
import { createMicrostepActionButton } from './createMicrostepActionButton'

export const intlMessages = defineMessages({
    ariaLabel: {
        defaultMessage: 'Dislike microstep',
        description: 'Dislike microstep button aria label'
    },
    label: {
        defaultMessage: 'Dislike',
        description: 'Dislike microstep button label'
    }
})

export const DislikeActionButton = createMicrostepActionButton({
    ariaLabel: intlMessages.ariaLabel,
    label: intlMessages.label,
    actionName: 'dislike',
    shortIconName: 'thumbs-down'
})
