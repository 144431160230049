import { Stack, ToggleButtonProps, useMediaQuery } from '@mui/material'
import {
    LeafIcon,
    LeafToggleButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { uniqueId } from 'lodash'
import { memo, useEffect, useMemo, useRef, PropsWithChildren } from 'react'
import { JourneyDto } from '../../../graphql/generated/autogenerated'
import { getJourneyIcon, getJourneyPalette } from '../../../utils/journeys'
import {
    initAnimationManager,
    type AnimationManager
} from './MicrostepToggleAnimations'
import MicrostepToggleBlip from './MicrostepToggleBlip'
import MicrostepToggleBurstElements from './MicrostepToggleBurstElements'

type MicrostepToggleProps = Omit<ToggleButtonProps, 'children'> & {
    journeyType?: JourneyDto['coreType']
}

const MicrostepToggle: React.FC<PropsWithChildren<MicrostepToggleProps>> = ({
    id = uniqueId('microstep-toggle-'),
    journeyType,
    selected,
    children,
    ...props
}) => {
    const { palette } = useTheme()
    const prefersReducedMotion = useMediaQuery(
        '(prefers-reduced-motion: reduce)'
    )

    const journeyPalette = useMemo(
        () =>
            journeyType
                ? getJourneyPalette(palette, journeyType)
                : palette.primary,
        [journeyType, palette]
    )

    const journeyIcon = useMemo(
        () => (journeyType ? getJourneyIcon(journeyType) : 'check'),
        [journeyType]
    )

    const buttonRef = useRef<HTMLButtonElement>(null)
    const checkMarkRef = useRef<HTMLDivElement>(null)
    const journeyIconRef = useRef<HTMLDivElement>(null)
    const burstGroupRef = useRef<HTMLDivElement>(null)

    const animationManagerRef = useRef<AnimationManager>()

    useEffect(() => {
        if (prefersReducedMotion) {
            return
        }

        animationManagerRef.current?.stopToggleAnimation()

        if (selected) {
            animationManagerRef.current?.runToggleAnimation()
        }
    }, [prefersReducedMotion, selected])

    useEffect(() => {
        if (
            buttonRef.current &&
            checkMarkRef.current &&
            burstGroupRef.current &&
            journeyIconRef.current &&
            !animationManagerRef.current
        ) {
            animationManagerRef.current = initAnimationManager(
                buttonRef.current,
                checkMarkRef.current,
                journeyIconRef.current,
                burstGroupRef.current
            )
        }
    }, [])

    return (
        <Stack position="relative">
            <MicrostepToggleBurstElements
                id={id}
                ref={burstGroupRef}
                journeyIcon={journeyIcon}
                journeyPalette={journeyPalette}
            />
            <MicrostepToggleBlip
                ref={journeyIconRef}
                journeyIcon={journeyIcon}
                journeyPalette={journeyPalette}
            />
            <LeafToggleButton
                {...props}
                ref={buttonRef}
                selected={selected}
                disableRipple
                color="primary"
                sx={{
                    '&&.Mui-selected': {
                        '&&.MuiToggleButton-primary': {
                            background: palette.primary.main,
                            color: palette.primary.contrastText
                        }
                    },
                    borderColor: palette.primary.main,
                    borderWidth: '2px'
                }}
            >
                <Stack ref={checkMarkRef}>
                    <LeafIcon
                        icon="check"
                        fontSize="small"
                        sx={{
                            color: selected
                                ? palette.primary.contrastText
                                : palette.text.primary
                        }}
                    />
                </Stack>
            </LeafToggleButton>
        </Stack>
    )
}

export default memo(MicrostepToggle)
