import React, { memo } from 'react'
import { LeafChip, LeafIcon, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { type JourneyPaletteItem } from '../../../../../utils/journeys'
import { type IconName } from '@fortawesome/fontawesome-common-types'

export type JourneyChipProps = {
    journeyShortName: string
    journeyPalette: JourneyPaletteItem
    journeyIcon?: IconName
    disabled?: boolean
    variant?: 'full' | 'icon'
}

function JourneyChip(props: JourneyChipProps) {
    const {
        journeyShortName,
        journeyPalette,
        journeyIcon,
        disabled = false,
        variant = 'full'
    } = props

    const { spacing } = useTheme()

    return (
        <LeafChip
            variant={'filled'}
            icon={
                journeyIcon && (
                    <LeafIcon
                        icon={journeyIcon}
                        fontSize={'small'}
                        fontWeight={900}
                        sx={{
                            color: journeyPalette.contrastText
                        }}
                    />
                )
            }
            label={variant === 'full' && journeyShortName}
            sx={{
                backgroundColor: journeyPalette.light,
                pl: spacing(1),
                '& .MuiChip-icon': {
                    ml: 0,
                    mr: variant === 'icon' && `-${spacing(2)}`
                }
            }}
            disabled={disabled}
            data-testid="journey-chip"
        />
    )
}

export default memo(JourneyChip)
