export enum ROUTES {
    JOURNEYS = '/journeys',
    ONBOARDING = '/onboarding',
    DAILY_INTELLIGENT_EXPERIENCE = '/journeys/daily',
    JOURNEY_MICROSTEPS = '/journeys/microsteps',
    JOURNEY_MICROSTEPS_BROWSE = '/journeys/microsteps/browse',
    JOURNEY_MICROSTEPS_SAVED = '/journeys/microsteps/saved',
    JOURNEY_MICROSTEPS_COMPLETED = '/journeys/microsteps/completed',
    CHALLENGE_PAGE = '/challenges/:challengeId',
    CHALLENGE_COMMUNITY = '/challenges/social',
    ARTICLE_PAGE = '/learn/articles/:articleId',
    ARTICLES = '/learn/articles',
    COURSE_PAGE = '/learn/courses/:courseId',
    PODCAST_PAGE = '/learn/podcasts/:podcastId',
    COURSE_MODULE = '/learn/courses',
    PODCAST_EPISODE = '/learn/podcasts',
    WEBINAR_PAGE = '/learn/webinars/:webinarId',
    ACHIEVEMENTS_PAGE = '/achievements',
    ACHIEVEMENTS_GARDEN = '/achievements/garden',
    RESETS = '/reset',
    CHALLENGES = '/challenges',
    LIBRARY = '/learn',
    MICROSTEPS = '/today/microsteps/',
    MICROSTEPS_DIALOG = '/today/microsteps/:microstepId',
    DOES_NOT_EXIST = '/does-not-exist',
    INSIGHTS_WELLBEING = '/insights/your-wellbeing',
    PROFILE = '/profile',
    COMPANY_RESOURCES = '/learn/resource'
}
