import { defineMessages } from 'react-intl'

const intlMessages = defineMessages({
    currentLevelText: {
        defaultMessage: 'Level {level}',
        description: 'Text for the current level of the journey'
    },
    currentLessonText: {
        defaultMessage: 'Lesson {lesson}',
        description: 'Text for the current lesson of the journey'
    }
})

type MessageValuesPair<TValues> = [
    (typeof intlMessages)[keyof typeof intlMessages],
    TValues
]

export function getCurrentLevelMessage(
    level: number
): MessageValuesPair<{ level: number }> {
    return [intlMessages.currentLevelText, { level }]
}

export function getCurrentLessonMessage(
    lesson: number
): MessageValuesPair<{ lesson: number }> {
    return [intlMessages.currentLessonText, { lesson }]
}
