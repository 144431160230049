import { gql } from '@thriveglobal/thrive-web-core'

export const GET_ALL_MICROSTEP_CHECKIN_PROGRESS = gql`
    query GetAllMicrostepsCheckinProgress(
        $from: ISODate!
        $to: ISODate!
        $fullMicrosteps: Boolean
    ) {
        today {
            getAllMicrostepCheckinProgressV2(
                from: $from
                to: $to
                fullMicrosteps: $fullMicrosteps
            ) {
                date
                microstepsCheckedIn
                microstepsCheckedInInfo {
                    journeyIds
                    journeys {
                        coreType
                        name
                        id
                    }
                }
                microstepsCheckedInCount
            }
        }
    }
`
