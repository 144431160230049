import { IconName } from '@fortawesome/fontawesome-common-types'
import { type SimplePaletteColorOptions, Stack } from '@mui/material'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'

type MicrostepToggleBlipProps = {
    journeyIcon: IconName
    journeyPalette: SimplePaletteColorOptions
}

const MicrostepToggleBlip = React.forwardRef<
    HTMLDivElement,
    MicrostepToggleBlipProps
>(({ journeyIcon, journeyPalette }, ref) => (
    <Stack
        ref={ref}
        role="presentation"
        sx={{ transform: 'scale(0)' }}
        position="absolute"
        bgcolor={journeyPalette.main}
        borderRadius="50%"
        p={1}
    >
        <LeafIcon
            icon={journeyIcon}
            fontSize="small"
            fontWeight={900}
            sx={{
                color: journeyPalette.contrastText
            }}
        />
    </Stack>
))

export default memo(MicrostepToggleBlip)
